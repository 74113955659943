<template>
  <span>
    <div class="col-12 md:col-12">
      <h6 id="cabecalho">CODATA - PBCONSIG</h6>
    </div>
  </span>
</template>

<script>
export default {
  name: 'CabecalhoRelatorio',
}
</script>

<style lang="scss" scoped>
#cabecalho {
  font-weight: bold;
}

@media print {
  #cabecalho {
    font-weight: bold;
  }
}
</style>
