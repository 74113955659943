<template>
  <span>
    <h3 id="titulo" class="text-center">Relatório Consolidado PBCONSIG</h3>
    <div>
      <table id="tabela">
        <thead>
          <tr>
            <th>CNPJ</th>
            <th>Consignatária</th>
            <th>Entidade</th>
            <th>Atividade</th>
            <th>Rubrica</th>
            <th>Periodo</th>
            <th>Quantidade de Averbações</th>
            <th>Valor Cobrado</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="data in relatorioCobranca">
            <tr v-for="(item, index) in data.detalhes" :key="index">
              <td>{{ data.cnpj | mascaraCNPJ }}</td>
              <td>{{ item.nomeFantasia }}</td>
              <td>{{ item.entidade }}</td>
              <td>{{ item.atividade }}</td>
              <td>{{ item.rubrica }}</td>
              <td>{{ item.mes }}/{{ item.ano }}</td>
              <td style="text-align: right">{{ item.qdtAverbacoes }}</td>
              <td class="formatarValor">
                <span v-if="relatorioStore.contentType == 'PDF'">
                  {{ item.valorCobrado | formatarValor }}
                </span>
                <span v-else>
                  {{ item.valorCobrado | formatarValorSemCifrao }}
                </span>
              </td>
            </tr>
            <tr id="subTotal" :key="data">
              <td colspan="5" style="text-align: right">SubTotal</td>
              <td colspan="2" style="text-align: right">
                {{ data.totalAverbacoes }}
              </td>
              <td colspan="1" style="text-align: right">
                <span v-if="relatorioStore.contentType == 'PDF'">
                  {{ data.subTotal | formatarValor }}
                </span>
                <span v-else>
                  {{ data.subTotal | formatarValorSemCifrao }}
                </span>
              </td>
            </tr>
          </template>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="5" style="text-align: right">Total Geral</td>
            <td colspan="2" style="text-align: right">
              {{ totalDeAverbacoes }}
            </td>
            <td colspan="1" class="formatarValor">
              {{ valorTotal | formatarValor }}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </span>
</template>
<script>
import { storeRelatorio } from '@/stores/relatorio'

export default {
  setup() {
    const relatorioStore = storeRelatorio()
    return { relatorioStore }
  },
  data() {
    return {
      relatorioCobranca: Map,
      colunas: [],
      valorTotal: 0,
      totalDeAverbacoes: 0,
    }
  },

  created() {
    this.relatorioCobranca = this.relatorioStore.response
    this.calcularTotalDeAverbacoesEvalorTotal()
  },

  mounted() {},

  methods: {
    calcularTotalDeAverbacoesEvalorTotal() {
      this.relatorioCobranca.forEach((data) => {
        this.valorTotal += data.subTotal
        this.totalDeAverbacoes += data.totalAverbacoes
      })
    },
  },
}
</script>
<style lang="scss" scoped>
#titulo {
  font-weight: bold;
}

.formatarValor {
  text-align: right !important;
}

#tabela {
  border-collapse: collapse;
  width: 100%;
  font-size: 14px;
}
#tabela th {
  background-color: #333;
  color: #fff;
  padding: 10px;
  text-align: left;
}

#tabela td {
  font-size: 12px;
  padding: 10px;
}

#tabela tfoot {
  background-color: #666666;
  color: #fff;
}

#subTotal {
  background-color: #f2f2f2;
}

@media print {
  #titulo {
    font-weight: bold;
  }

  .formatarValor {
    text-align: right !important;
  }

  #tabela {
    border: 1px solid black;
    border-collapse: collapse;
    width: 100%;
    font-size: 12px;
  }
  #tabela th {
    background-color: #333;
    color: #fff;
    padding: 10px;
    text-align: left;
  }

  #tabela td {
    font-size: 10px;
    padding: 10px;
  }

  #tabela tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  #tabela tfoot {
    background-color: #666666;
    color: #fff;
  }
}
</style>
